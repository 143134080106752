import React from 'react'
import { UVPContext } from '../context/UVPContext';

export default function Uvp() {
    const color = ['icon-box icon-box-pink','icon-box icon-box-cyan','icon-box icon-box-green']
    const icon = ['bx bxl-dribbble','bx bx-file','bx bx-tachometer']
  return (
    <section className="services">
        <div className="container">
            <div className="section-title">
                <h2>橫渠的教育理念</h2>
                <p>橫渠的存在，讓開創型人才能找到發展的一片天，也讓企業主找到格局宏大、富影響力與行動力的新世代。</p>
            </div>

            <div className="row">
                {UVPContext.map((item, index) => (
                    <div className="col-md-6 col-lg-4 d-flex align-items-stretch" data-aos="fade-up">
                        <div className={color[index%color.length]}>
                            <div className="icon"><i className={icon[index%icon.length]}></i></div>
                            <h4 className="title"><a href="">{item.title}</a></h4>
                            <p className="description">{item.description}</p>
                        </div>
                    </div>
                ))}
            </div>
         </div>
    </section>
  )
}
