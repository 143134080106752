import React from "react"
import { Chrono } from "react-chrono";
import {milestone} from '../context/milestone';


export default function Roadmap() {
    return(
        <>
            <section class="breadcrumbs">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2>里程碑</h2>
                        <ol>
                            <li><a href="">橫渠產學聯盟</a></li>
                            <li>里程碑</li>
                        </ol>
                    </div>

                </div>
            </section>
            <section class="breadcrumbs">
                <div class="container">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2>里程碑</h2>
                        <ol>
                            <li><a href="">橫渠產學聯盟</a></li>
                            <li>里程碑</li>
                        </ol>
                    </div>

                </div>
            </section>
            <section class="features">
                <div class="container">
                <div class="section-title">
                    <h2>建造偉大的橋樑，並非一蹴可幾！</h2>
                    <p>橫渠里程碑</p>
                </div>
                <div data-aos="fade-up">
                    {/* <div style={{ width: "100%", height: "700px" }}> */}
                        <Chrono 
                            items={milestone}  
                            slideShow  
                            cardHeigth="300"
                            mode = "VERTICAL" 
                            showAllCardsHorizontal 
                            theme = { { 
                            primary : '#1e4356' , 
                            secondary : '#68A4C4' , 
                            titleColor : 'black' , 
                            titleColorActive : 'white' , 
                            } } 
                            
                        />
                    {/* </div> */}
                </div>
            </div>
            </section>
        </>
    )
}