import { useState, useEffect } from 'react';
import LoadingSpinner from '../components/Spinner';
import { Link} from 'react-router-dom';

export default function Blog() {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [tag, setTag] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        // fetch data
        setIsLoading(true);
        const dataFetch = async () => {
            const res = await (
                await fetch(
                    "https://script.google.com/macros/s/AKfycbyPLh159h-eIAwuLZr_NuyWG9vq695fYvlCGufIu3F6h2J5tTjWFv_NhyyixHi2ZdERwg/exec?name=文章分享&url=https://docs.google.com/spreadsheets/d/1b16RMYzoRGJeUcvKyNyWDtkpNCEkSBABP8BEzPOdLms/edit#gid=0"
                )
            ).json();
            
            // find unique tag in res
            var allTag = [];
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
            
            
            res.forEach((item) => {
                // replace all , to  " "
                item.Tag = item.Tag.replace(/ /g, "").replace(/,/g, " ");
                item.Tag.split(" ").forEach((tag) => {
                    allTag.push(tag);
                });
                item.index = new Date(item.時間戳記).getTime();
                item.時間戳記 = new Date(item.時間戳記).toLocaleDateString();
                
            });
            var uniqueTag = allTag.filter(onlyUnique);
            console.log(res, uniqueTag);
            // set state when the data received
            setData(res);
            setAllData(res);
            setTag(uniqueTag);
            setIsLoading(false);
        };

        dataFetch();
    }, []);

    const handleTag = (selectedTag) => {
        //get "tagContainer" element
        const tagContainer = document.getElementById("TagContainer");
        //get all "tag" element
        const tag = tagContainer.getElementsByClassName("tag");
        //remove "active" class from all "tag" element
        for (var i = 0; i < tag.length; i++) {
            tag[i].classList.remove("filter-active");
        }
        //add "active" class to the clicked "tag" element
        const clickedTag = document.getElementById(selectedTag);
        if(selectedTag === "all"){
            setData(allData);
            //add "active" class to the clicked "tag" element
            clickedTag.classList.add("filter-active");
            return;
        }
        clickedTag.classList.add("filter-active");
        const allBlogs = allData;
        const filteredBlogs = allBlogs.filter((item) =>
            item.Tag.toLowerCase().includes(selectedTag.toLowerCase().trim())
        );
        setData(filteredBlogs);
    }
    return(
        <div>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                    <h2>文章分享</h2>
                    <ol>
                        <li><a href="index.html">橫渠產學聯盟</a></li>
                        <li>文章分享</li>
                    </ol>
                    </div>
                </div>
            </section>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                    <h2>文章分享</h2>
                    <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>文章分享</li>
                    </ol>
                    </div>

                </div>
            </section>
            
            <section className="portfolio">
            
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-12" id="TagContainer">
                            <ul id="portfolio-flters">
                            <li data-filter="*" className="tag filter-active" id="all" onClick={() => handleTag("all")} >All</li>
                            {tag.map((item, index) => (
                                <li key={index} 
                                    onClick={() => handleTag(item)}
                                    className="tag filter"
                                    id={item}
                                >
                                    {item}
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                    {isLoading ? <LoadingSpinner /> : (
                    <div className="row portfolio-container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500" >
                    
                    {data.map((item, index) => (
                                
                                <div className="col-lg-4 col-md-6 portfolio-wrap " 
                                style={{backgroundColor: "#f8f9fa", borderRadius: "10px", marginBottom: "20px", padding: "20px"}}
                                key={item.index}>
                                    <Link
                                        to={{
                                            pathname: `${item.index}`,
                                        }}
                                    >

                                        <div className="portfolio-item" style={{position: "relative"}}>
                                            <img 
                                                src={item.Picture} 
                                                className="img-blog" 
                                                alt=""
                                                style={{width:"100%", height:"100%", objectFit:"cover"}}
                                            />
                                            <div className="portfolio-info">
                                                <div>
                                                    <h3>{item.Tag}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <h3 style={{fontSize: "22px",color: "#1e4356", fontWeight: "bold"}}><a href={`blog/${item.index}`}>{item.Title}</a></h3>
                                    <li className="d-flex"><i style={{marginRight:"5px"}} className="bi bi-person"></i>{item.Author}<i className="bi bi-clock" style={{marginLeft:"20px", marginRight:"5px"}}></i> <time dateTime="2020-01-01"> {item.時間戳記}</time></li>
                                    <p style={{ whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",maxWidth: "100%"}}>
                                        {item.Article}
                                    </p>
                                </div>
                        ))
                    }
                    
                    
                    </div>
                    )}

                </div>
                
            </section>
            
        </div>

    )
}