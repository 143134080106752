export default function Contact() {
    return(
        <div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                    <h2>加入橫渠產學聯盟</h2>
                    <ol>
                        <li><a href="index.html">橫渠產學聯盟</a></li>
                        <li>加入橫渠產學聯盟</li>
                    </ol>
                    </div>

                </div>
            </section>
            <section className="breadcrumbs">
            <div className="container">

                <div className="d-flex justify-content-between align-items-center">
                <h2>加入橫渠產學聯盟</h2>
                <ol>
                    <li><a href="/">橫渠產學聯盟</a></li>
                    <li>加入橫渠產學聯盟</li>
                </ol>
                </div>

            </div>
            </section>
            <section className="contact" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
            <div className="container">

                <div className="row">

                {/* <div className="col-lg-6">

                    <div className="row">
                    <div className="col-md-12">
                        <div className="info-box">
                        <i className="bx bx-map"></i>
                        <h3>Our Address</h3>
                        <p>A108 Adam Street, New York, NY 535022</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="info-box">
                        <i className="bx bx-envelope"></i>
                        <h3>Email Us</h3>
                        <p>info@example.com<br/>contact@example.com</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="info-box">
                        <i className="bx bx-phone-call"></i>
                        <h3>Call Us</h3>
                        <p>+1 5589 55488 55<br/>+1 6678 254445 41</p>
                        </div>
                    </div>
                    </div>

                </div> */}
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScpp9c3XrytoQ8G2Vnh6mM0ERx0a9L8f0AAbEaIFH6RXMUY3g/viewform?embedded=true" height="1000" frameborder="0" marginheight="0" marginwidth="0" scroll="no">載入中…</iframe>

                <div className="col-lg-6">

                </div>
                <div className="col-lg-6">

                    {/* <form  role="form" className="php-email-form">
                    <div className="row">
                        <div className="col-md-6 form-group">
                        <input type="text" name="name" className="form-control" id="name" placeholder="姓名" required/>
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                            <select name="department" id="department" className="form-control" required>
                                <option value="">選擇聯繫內容</option>
                                <option value="學生會員申請">學生會員申請</option>
                                <option value="個人會員申請">個人會員申請</option>
                                <option value="團體會員申請">團體會員申請</option>
                                <option value="贊助會員申請">贊助會員申請</option>
                                <option value="合作邀約">合作邀約</option>
                                <option value="其他">其他</option>
                            </select>    
                        </div>
                    </div>
                    <div className="form-group mt-3">
                    <input type="text" className="form-control" name="contact" id="contact" placeholder="Email, LineID, 電話號碼則一" required/>
                    </div>
                    <div className="form-group mt-3">
                        <input type="text" className="form-control" name="identify" id="identify" placeholder="單位與職稱" required/>
                    </div>
                    <div className="form-group mt-3">
                        <textarea className="form-control" name="message" rows="5" placeholder="留言給橫渠" ></textarea>
                    </div>
                    <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Send Message</button></div>
                    </form>*/}
                </div> 

                </div>

            </div>
            </section>
            <section className="map mt-2">
            <div className="container-fluid p-0">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3024.2219901290355!2d-74.00369368400567!3d40.71312937933185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a23e28c1191%3A0x49f75d3281df052a!2s150%20Park%20Row%2C%20New%20York%2C%20NY%2010007%2C%20USA!5e0!3m2!1sen!2sbg!4v1579767901424!5m2!1sen!2sbg" frameborder="0" style="border:0;" allowfullscreen=""></iframe> */}
            </div>
            </section>
        </div>
    )
}