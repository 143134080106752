import React from 'react'
import { useState, useEffect } from 'react';
import LoadingSpinner from '../components/Spinner';

export default function Team() {
    const [data, setData] = useState([]);
    const [founder, setFounder] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        // fetch data
        setIsLoading(true);
        const dataFetch = async () => {
            const res = await (
                await fetch(
                "https://script.google.com/macros/s/AKfycbyPLh159h-eIAwuLZr_NuyWG9vq695fYvlCGufIu3F6h2J5tTjWFv_NhyyixHi2ZdERwg/exec?name=經營團隊&url=https://docs.google.com/spreadsheets/d/1b16RMYzoRGJeUcvKyNyWDtkpNCEkSBABP8BEzPOdLms/edit#gid=0"
                )
            ).json();
            // set state when the data received
            console.log(res)
            try{
                for(var i=0;i<res.length;i++){
                    // if url include drive.google.com/file/d
                    if(res[i].GooglePhotoURL!==undefined){
                        res[i].PhotoURL = "https://drive.google.com/uc?id="+res[i].GooglePhotoURL.split("id=")[1]
                    }
                    if(res[i].PhotoURL.includes("drive.google.com/file/d")){
                        res[i].PhotoURL = "https://drive.google.com/uc?id="+res[i].PhotoURL.split("d/")[1].split("/")[0]
                    }
                }
            } catch(e){
                console.log(e)
            }
            // if type = "理事會" then setFounder
            var founder = res.filter((item) => item.type === "理事會");
            setFounder(founder);
            // remove founder from res
            var team = res.filter((item) => item.type === "學生");
            console.log(team)
            setData(team);
            setIsLoading(false);
        };

        dataFetch();
    }, []);
    
    return (
        <div>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>會員</h2>
                        <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>會員</li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>組織成員</h2>
                        <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>組織成員</li>
                        </ol>
                    </div>
                </div>
            </section>
            {isLoading ? <LoadingSpinner /> : (     
            <>
            <section className="team" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                <div className="container"> 
                <div className="section-title">
                    <h2>理事會
                    </h2>
                    <p></p>
                </div>     
                <div className="row" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                   {founder.map((item,index) => (
                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" key={index}>
                        <div className="member">
                            <div className="member-img" data-aos-easing="ease-in-out" data-aos-duration="500">
                                 <img 
                                    src={item.PhotoURL} 
                                    className="img-fluid" 
                                    alt=""
                                    
                                />
                                {/* <div className="social">
                                    <a href={team.Twitter}><i className="bi bi-twitter"></i></a>
                                    <a href={team.Facebook}><i className="bi bi-facebook"></i></a>
                                        <a href={team.Github}><i className="bi bi-github"></i></a>
                                        <a href={team.Linkedin}><i className="bi bi-linkedin"></i></a>
                                    </div> */}
                                </div>
                                <div className="member-info">
                                    <h4>{item.Name} {item.EnglishName}</h4>
                                    <span>{`${item.Department} ${item.Title}`}</span>
                                    <p style={{whiteSpace: "pre-wrap"}}>
                                        {item.Descript}
                                    </p>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="team" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                <div className="container"> 
                <div className="section-title">
                    <h2>學生幹部</h2>
                    <p></p>
                 </div>       
                    <div className="row" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                       {data.map((team,index) => (
                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch" key={index}>
                            <div className="member">
                                <div className="member-img" data-aos-easing="ease-in-out" data-aos-duration="500">
                                    {/* <img 
                                        src={team.PhotoURL} 
                                        className="img-fluid" 
                                        alt=""
                                        
                                    /> */}
                                    {/* <div className="social">
                                        <a href={team.Twitter}><i className="bi bi-twitter"></i></a>
                                        <a href={team.Facebook}><i className="bi bi-facebook"></i></a>
                                        <a href={team.Github}><i className="bi bi-github"></i></a>
                                        <a href={team.Linkedin}><i className="bi bi-linkedin"></i></a>
                                    </div> */}
                                </div>
                                <div className="member-info">
                                    <h4>{team.Name} {team.EnglishName}</h4>
                                    <span>{`${team.Department} ${team.Title}`}</span>
                                    <p style={{whiteSpace: "pre-wrap"}}>
                                        {team.Descript}
                                    </p>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </section>
            </>
            )}
            
        </div>
    )
}