import { useState, useEffect } from 'react';
import LoadingSpinner from '../components/Spinner';
import {useLocation} from 'react-router-dom';
export default function Article() {
    const ifo= useLocation()
    const key = ifo.pathname.split("/")[2]
    console.log(key)
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        // fetch data
        setIsLoading(true);
        const dataFetch = async () => {
            const res = await (
                await fetch(
                    "https://script.google.com/macros/s/AKfycbyPLh159h-eIAwuLZr_NuyWG9vq695fYvlCGufIu3F6h2J5tTjWFv_NhyyixHi2ZdERwg/exec?name=文章分享&url=https://docs.google.com/spreadsheets/d/1b16RMYzoRGJeUcvKyNyWDtkpNCEkSBABP8BEzPOdLms/edit#gid=0"
                )
            ).json();
            
            // find unique tag in res

            res.some(function(item) {
                console.log(item.時間戳記,new Date(item.時間戳記).getTime())
                if(new Date(item.時間戳記).getTime() == key){
                    item.Tag = item.Tag.replace(/, /g, " #");
                    item.Tag = "#"+item.Tag
                    item.index = new Date(item.時間戳記).getTime();
                    item.時間戳記 = new Date(item.時間戳記).toLocaleDateString();
                    console.log(item)
                    setData(item)
                    return true;
                } else {
                    return false
                }
            })
            console.log(data);
            // set state when the data received
            // setData(data);
            setIsLoading(false);
        };

        dataFetch();
    }, []);
    return(
        <div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>資源共享</h2>

                        <ol>
                            <li><a href="/">橫渠產學聯盟</a></li>
                            <li><a href="/blog">資源共享</a></li>
                            <li>blog.Title</li>
                        </ol>
                    </div>

                </div>
            </section>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                        <h2>文章分享</h2>

                        <ol>
                            <li><a href="/">橫渠產學聯盟</a></li>
                            <li><a href="/blog">文章分享</a></li>
                            <li>{data.Title}</li>
                        </ol>
                    </div>

                </div>
            </section>
           
            <section id="blog" className="blog">
                <div className="container" >
                    {isLoading ? <LoadingSpinner /> : (
                    <div className="row" data-aos="fade-up">

                        <div className="col-lg-12 entries">

                            <article className="entry entry-single">

                                <div className="">
                                    <img src={data.Picture} width="100%" alt="" className="img-fluid"/>
                                </div>

                                <h2 className="entry-title mt-3">
                                    <a href="blog-single.html">{data.Title}</a>
                                </h2>

                                <div className="entry-meta">
                                    <ul>
                                    <li className="d-flex align-items-center"><i className="bi bi-person"></i> {data.Author}</li>
                                    <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <time dateTime="2020-01-01">{data.時間戳記}</time></li>
                                    <li className="d-flex align-items-center"> {data.Tag}</li>
                                    <li className="d-flex align-items-center" >
                                        來源： <a href={data.Reference} style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",maxWidth: "70%"}}>{data.Reference}</a></li>
                                    </ul>
                                </div>

                                <div className="entry-content" style={{whiteSpace: "pre-wrap"}}>
                                {data.Article}

                                </div>
                        

                            </article>

                            {/* <div className="blog-author d-flex align-items-center">
                                <img src="{{author.Photo.url}}" className=" float-left" style={{borderRadius:"30% !important"}}alt=""/>
                                 <div>
                                    <h4>{data.Author}</h4>
                                    <div className="entry-content">
                                        <p>本文作者</p>
                                    </div>
                                    <p style={{wordBreak:"break-all"}}>
                                    出處：{data.Reference}
                                    </p>
                                </div>
                            </div> */}
                        </div>

                        <div className="blog-comments">

                        </div>

                    </div>
                    )}
                    <div className="col-lg-4">

                    </div>
                </div>
            </section>
            
        </div>
    )
}