import "./style/nav.css";


function handleClick(e) {
    // this.classList.toggle('bi-list')
    // this.classList.toggle('bi-x')
    let navbar = document.getElementById('navbar')
    navbar.classList.toggle('navbar-mobile')
    let mobileNav = document.getElementById('mobile-nav')
    mobileNav.classList.toggle('bi-list')
    mobileNav.classList.toggle('bi-x')

}

function handleScroll(e) {
    e.stopPropagation()
    if ( document.getElementById('navbar').classList.contains('navbar-mobile')) {
        e.preventDefault()
        // let all <ul> tag elements 
        // this element
        try{
            e.target.nextElementSibling.classList.toggle('dropdown-active')
        } catch (e) {
            console.log(e)
        }
        
      }
    }
export default function Nav(){
    
    return (
        <nav id="navbar" className="navbar">
            <ul>
                <li><a className="active"  href="/">首頁</a></li>
                <li className="dropdown"><a className="active" href="#" onClick={handleScroll}><span>關於我們</span> <i className="bi bi-chevron-down"></i></a>
                <ul>
                    <li><a href="/about">教育理念</a></li>
                    <li><a href="/team">組織成員</a></li>
                    <li><a href="/member">橫渠會員</a></li>
                    <li><a href="/partner">合作夥伴</a></li>
                </ul>
                </li>
                <li><a className="active"  href="/services">服務介紹</a></li>
                <li><a className="active"  href="/activity">活動推廣</a></li>
                <li><a className="active" href="/blog">文章分享</a></li>
                <li className="dropdown"><a className="active" href="#"  onClick={handleScroll} ><span>歷程</span> <i className="bi bi-chevron-down" ></i></a>
                <ul id="Event">
                    <li><a href="/photo">相簿</a></li>
                    <li><a href="/roadmap">里程碑</a></li>
                </ul>
                </li>
                <li><a className="active" href="/contact">聯絡我們</a></li>
            </ul>
            
            <i className="bi bi-list mobile-nav-toggle" id = "mobile-nav" onClick={handleClick}></i>
        </nav>
    )
}