import { useState, useEffect } from 'react';
import LoadingSpinner from '../components/Spinner';
import {useLocation} from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade,Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css/effect-fade';
import "./style/swiper.css"
    

export default function Photo() {
    const ifo= useLocation()
    const key = ifo.pathname.split("/")[2]

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);
        // fetch data
        const dataFetch = async () => {
            const res = await (
                await fetch(
                    "https://script.google.com/macros/s/AKfycbyPLh159h-eIAwuLZr_NuyWG9vq695fYvlCGufIu3F6h2J5tTjWFv_NhyyixHi2ZdERwg/exec?name=相簿&url=https://docs.google.com/spreadsheets/d/1b16RMYzoRGJeUcvKyNyWDtkpNCEkSBABP8BEzPOdLms/edit#gid=0"
                )
            ).json();
            // set state when the data received

            res.forEach((item) => {
                if(new Date(item.時間戳記).getTime() == key){
                    console.log(key)
                    item.Tag = item.Tag.replace(/, /g, " #");
                    item.Tag = "#"+item.Tag

                    item.Photo= item.Photo.split(",")
                    for(let i=0;i<item.Photo.length;i++){
                        item.Photo[i] = "https://drive.google.com/uc?id="+item.Photo[i] .split(",")[0].split("id=")[1]
                    }
                    // replace all , to  " "
                    item.index = new Date(item.時間戳記).getTime();
                    item.Date= item.Date.split("T")[0]
                    setData(item)
                    console.log(item)
                    return true;
                } else {
                    return false
                }
            });
            setIsLoading(false);
        };
        dataFetch();
    }, [key]);
    
    return(
        <div>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                    <h2>{data.Name}</h2>
                    <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>活動</li>
                    </ol>
                    </div>

                </div>
            </section>
            <section className="breadcrumbs">
                <div className="container">

                    <div className="d-flex justify-content-between align-items-center">
                    <h2>{data.Name}</h2>
                    <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li><a href="/photo">相簿</a></li>
                    </ol>
                    </div>

                </div>
            </section>
            <section>
            
                <div id="portfolio-details" className="portfolio-details">
               
                    <div className="container">
                    
                    {isLoading ? <LoadingSpinner /> : (
                        <div className="row gy-4">
                            <div className="col-lg-8">
                                <Swiper
                                    modules={[EffectFade,Navigation, Pagination, Scrollbar, A11y]}
                                    navigation
                                    // pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                    spaceBetween={0}
                                    slidesPerView={1}
                                    effect="fade"
                                    loop={true}
                                    // onSlideChange={() => console.log('slide change')}
                                    // onSwiper={(swiper) => console.log(swiper)}
                                >
                                    {data.Photo?.map((photo,index) => (
                                        <SwiperSlide key={index}>
                                            <img 
                                            src={photo} 
                                            style={{width:"100%", height:"100%", objectFit:"cover"}}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                
                            </div>

                            <div className="col-lg-4">
                                <div className="portfolio-description">
                                    <h2>{data.Name}</h2>
                                    <strong>時間</strong>: {data.Date}<br/>
                                    <p className="entry-content" style={{whiteSpace: "pre-wrap"}}>
                                        {data.Descript}
                                    </p>
                                </div>
                            </div>
                           
                        </div>
                         )}
                    </div>
                
                </div>

            </section>
        </div>
    )
}