import { useState, useEffect } from 'react';
import LoadingSpinner from '../components/Spinner';
import { Link} from 'react-router-dom';
export default function Gallery() {
    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [tag, setTag] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        // fetch data
        setIsLoading(true);
        const dataFetch = async () => {
            const res = await (
                await fetch(
                    "https://script.google.com/macros/s/AKfycbyPLh159h-eIAwuLZr_NuyWG9vq695fYvlCGufIu3F6h2J5tTjWFv_NhyyixHi2ZdERwg/exec?name=相簿&url=https://docs.google.com/spreadsheets/d/1b16RMYzoRGJeUcvKyNyWDtkpNCEkSBABP8BEzPOdLms/edit#gid=0"
                )
            ).json();
            // set state when the data received
            const uniqueTag = [];
            res.forEach((item) => {
                item.Photo = "https://drive.google.com/uc?id="+item.Photo.split(",")[0].split("id=")[1]
                item.Tag.split(",").forEach((tag) => {
                    if (!uniqueTag.includes(tag)) {
                        uniqueTag.push(tag);
                    }
                });
                // replace all , to  " "
                item.Tag = item.Tag.replace(/, /g, " ");
                item.index = new Date(item.時間戳記).getTime();
            });
            console.log(res);
            setData(res);
            setAllData(res);
            setTag(uniqueTag);
            setIsLoading(false);
        };

        dataFetch();
    }, []);

    const handleTag = (selectedTag) => {
        //get "tagContainer" element
        const tagContainer = document.getElementById("TagContainer");
        
        //get all "tag" element
        const tag = tagContainer.getElementsByClassName("tag");
        //remove "active" class from all "tag" element
        for (var i = 0; i < tag.length; i++) {
            tag[i].classList.remove("filter-active");
        }
        //add "active" class to the clicked "tag" element
        const clickedTag = document.getElementById(selectedTag);
        if(selectedTag === "all"){
            setData(allData);
            //add "active" class to the clicked "tag" element
            clickedTag.classList.add("filter-active");
            return;
        }
        clickedTag.classList.add("filter-active");
        const all = allData;
        const filtered = all.filter((item) =>
            item.Tag.toLowerCase().includes(selectedTag.toLowerCase().trim())
        );
        setData(filtered);
    }
        


    return(
        <div>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>相簿</h2>
                        <ol>
                            <li><a href="/">橫渠產學聯盟</a></li>
                            <li>活動</li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>相簿</h2>
                        <ol>
                            <li><a href="/">橫渠產學聯盟</a></li>
                            <li>活動</li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="portfolio">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" id="TagContainer">
                            <ul id="portfolio-flters">
                            <li data-filter="*" className="tag filter-active" id="all" onClick={() => handleTag("all")} >All</li>
                            {tag.map((item, index) => (
                                <li className="tag"  key={index} id={item} onClick={() => handleTag(item)}>{item}</li>
                            ))}
                            </ul>
                        </div>
                    </div>
                    
                    {isLoading ? <LoadingSpinner /> : (
                    <section className="row portfolio-container" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
                     {data.map((item, index) => (
                        <div className="col-lg-4 col-md-6 portfolio-wrap {{tag}}">   
                            <div className="portfolio-item" style={{position:"relative", minHeight: "300px !important"}}>                        
                                    <Link
                                        to={{
                                            pathname: `${item.index}`,
                                        }}
                                    >
                                    <img 
                                        src={item.Photo}
                                        className="img-fit" 
                                        alt=""
                                        style={{width:"100%", height:"100%", objectFit:"cover"}}
                                    />
                                    <div className="portfolio-info">
                                        <h3>{item.Name}</h3>
                                        <div>
                                            <p style={{color:"white"}}>{item.Date.split("T")[0]}</p>
                                            <p style={{color: "rgba(255, 255, 255, 0.574)"}}>{item.Tag}</p>
                                        </div>
                                    </div>
                                    </Link>
                            </div>
                        </div>
                    ))}
                    </section>   
                    )} 
                </div>
            </section>
        </div>
    )
}