import React from "react"
import { Chrono } from "react-chrono";
import {events} from '../context/events';
import Calender from '../components/Calender';
export default function Activity() {
    return(
        <div>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                    <h2>活動推廣</h2>
                    <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>活動推廣</li>
                    </ol>
                    </div>
                </div>
            </section>
            <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                <h2>活動推廣</h2>
                <ol>
                    <li><a href="/">橫渠產學聯盟</a></li>
                    <li>活動推廣</li>
                </ol>
                </div>
            </div>
            </section>
            <section className="service" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className='col-md-8'>
                            <div data-aos="fade-up">
                                <Chrono 
                                    items={events}  
                                    slideShow  
                                    cardHeigth="300"
                                    mediaHeight="400"
                                    mode = "VERTICAL" 
                                    theme = { { 
                                    primary : '#1e4356' , 
                                    secondary : '#68A4C4' , 
                                    titleColor : 'black' , 
                                    titleColorActive : 'white' , 
                                    } } 
                                    
                                />
                            </div>
                        </div>

                        <div className='col-md-4'>
                            <Calender/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}