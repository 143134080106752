import Uvp from '../components/Uvp'

export default function About() {
    return(
        <div>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                    <h2>關於我們</h2>
                    <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>關於我們</li>
                    </ol>
                    </div>
                </div>
                </section>
                <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                    <h2>關於我們</h2>
                    <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>關於我們</li>
                    </ol>
                    </div>
                </div>
                </section>
                <section className="about" data-aos="fade-up">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <img 
                                    src="/assets/img/About/橫渠介紹1.png" 
                                    className="img-fluid" 
                                    alt=""
                                />
                            </div>
                            <div className="col-lg-6 pt-4 pt-lg-0">
                                {/* <h3>從天賦興趣, 動機出發，打造能力導向的職涯網路</h3>
                                <p className="fst-italic">
                                橫渠產學聯盟致力於搭建出「滿足企業人才需求的課程平台」，讓大學順利銜接職場。
                                </p>
                                <ul>
                                <li><i className="bi bi-check2-circle"></i> Why : 企業缺人才，人才缺環境與動機，橫渠將彌補大學的產學落差。</li>
                                <li><i className="bi bi-check2-circle"></i> What : 打造以人才為中心的學習成長生態環境。</li>
                                <li><i className="bi bi-check2-circle"></i> Who : 有創新動機、缺資源的人才， 有理念和願意創新成長的企業教育夥伴。</li>
                                <li><i className="bi bi-check2-circle"></i> How: 運用既有體系資源/協會平台媒合執行，小規模典範轉移入口與出口地定錨。</li>
                                </ul>
                                <p>
                                企業想讓大學生學什麼，在平台上可以丟出需求，經費由平台向有人才需求的企業發起群募。橫渠協助企業在平台上找規劃、找師資、找資源、找學校，而且課程可以通過學校正規的認可，得到學分。平台讓整個社會參與課程 創造。企業拋磚引玉出錢出力外，平台也可以誘出整個民間智慧與儲蓄從事教育，才能發揮改善現有教育現況的巨大效益。 „
                                橫渠認為，台灣是孕育創新的教育模式的絕佳孵化場域。 透過平台，讓利益關係人互相依存，漸漸變成一種準結盟關係，形成利益共同體，可以集思廣益，連結社會資源，讓各個參與者互蒙其利，且能應付千變萬化的企業人才需求。
                                </p> */}
                                <img 
                                    src="/assets/img/About/橫渠介紹2.png" 
                                    className="img-fluid" 
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <Uvp />
        </div>
    )
}