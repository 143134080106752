export const serviceOutline = [
  {
    "id": "1",
    "title": "職涯啟蒙課程",
    "image": "/assets/img/service/1.png",
    "description": "引領學員，認識選擇產業職場發展路線，從產業角度回看大學應作何準備，並提供產業專家講座，讓學員了解產業內的職場發展與職涯規劃。",
    "link": "",
    "display": "true",
  },
  {
      "id": "2",
      "title": "職涯啟蒙課程",
      "image": "/assets/img/service/2.png",
      "description": "結合：各類創業及領導學程（降龍十八掌）\n對象：具備開創及領導潛力的學生。\n主題：學校或社團專辦的營，需要有足夠的互動討論。",
      "link": "",
      "display": "true",
  },
  {
    "id": "3",
    "title": "理工學生的創新商業思維",
    "image": "/assets/img/service/3.png",
    "description": "結合：各學院的X X X 概論課程,如工科概論，半導體概論，電機概論\n主題：創新，商業，系統解決問題。\n範例：中興大學的生技全天演講",
    "link": "",
    "display": "true",
  },
  {
    "id": "4",
    "title": "人文社會學生的產業科普",
    "image": "/assets/img/service/4.png",
    "description": "引導學生進入科技產業的基本科技知識。\n狀態：準備中。",
    "link": "",
    "display": "true",
  },
  {
    "id": "5",
    "title": "職涯講座",
    "image": "/assets/img/service/5.png",
    "description": "結合：通識中心及職涯組。舉辦各類講座。\n主題：產業及職業介紹講座\n說明：\n我們接觸許多大學的高層主管，例如成大的coach,逢甲的翟院長，人社陳介英所長。以及南台科技的副校長。他們的共同點：學生需要更多的對產業職涯認識。",
    "link": "",
    "display": "true",
  },
  {
    "id": "6",
    "title": "諮詢服務",
    "image": "/assets/img/service/6.png",
    "description": "1. 協助系所進行產業專業課程規劃，介紹業師。\n2. 通識中心課程結合。（如領導學，創新，產業應用實務，系統解決問題，職涯）\n3. 學校或系所的邀約產業演講或座談。\n4. 各類專案式學習與競賽講習的訓練課程。\n5. 職涯中心的演講。\n6. 實習安排\n7. 安排學生輔導教練。指點學生職涯疑惑",
    "link": "",
    "display": "true",
  }
      
];

