export const milestone = [
  {
    title: "2022 4月",
    cardTitle: "籌備橫渠產學聯盟",
    url: "",
    cardSubtitle:
      "初始團隊建立\n招募初始會員.",
    cardDetailedText: ``,
    media: {
      name: "dunkirk beach",
      source: {
        url:
          "/assets/img/Roadmap/Capture_2022_12_17_00_57_04_977.png"
      },
      type: "IMAGE"
    },
  },
  {
    title: "2022 7月",
    cardTitle: "內政部正式通知",
    url: "",
    cardSubtitle:
      "成為台灣成為台灣政府認可的人才輔導組織",
    cardDetailedText: ``,
    media: {
      name: "dunkirk beach",
      source: {
        url:
          "/assets/img/Roadmap/Capture_2022_12_17_00_56_55_1.png"
      },
      type: "IMAGE"
    },
  },
  {
    title: "2022 8月",
    cardTitle: "協會正式成立",
    url: "",
    cardSubtitle:
      "2022.08.23 第一次會員大會",
    cardDetailedText: ``,
    media: {
      name: "dunkirk beach",
      source: {
        url:
          "/assets/img/Roadmap/Capture_2022_12_17_00_56_55_0.png"
      },
      type: "IMAGE"
    },
  },
  {
    title: "2023 1月",
    cardTitle: "X-Bridge.tw",
    url: "",
    cardSubtitle:
      "官網正式上線",
    cardDetailedText: ``,
    media: {
      name: "dunkirk beach",
      source: {
        url:
          "/assets/img/Roadmap/Capture_2022_12_17_00_57_18_833.png"
      },
      type: "IMAGE"
    },
  },
  ];
  
  