
export default function Footer(){
    const ulStyle = {
        listStyleType: "none",
        padding: 0,
        margin: 0
    }
    const footerAsStyle = {
      // marginBottom: "10%"
    }
    const footerStyle = {
      // scroll down always at the bottom
      // position: "absolute",
      position:"relative",
      bottom: 0,
      width: "100%",
      height: "auto",

    }
    return (
        <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500" style={ footerStyle}>
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6" style={footerAsStyle}>
                  <h4 >橫渠的理念</h4>
                  <ul style={ulStyle}>
                    <li><i className="bx bx-chevron-right"></i> <a href="/about">創會願景與使命</a></li>
                    <li><i className="bx bx-chevron-right"></i> <a href="/about">帶給學生的價值與改變</a></li>
                  </ul>
                </div>
      
                <div className="col-lg-3 col-md-6" style={footerAsStyle}>
                  <h4>我們的服務</h4>
                  <ul style={ulStyle}>
                    <li><i className="bx bx-chevron-right"></i> <a href="/services">學涯陪訓課</a></li>
                    <li><i className="bx bx-chevron-right"></i> <a href="/services">MENTOR 制度</a></li>
                    <li><i className="bx bx-chevron-right"></i> <a href="/services">專案課程</a></li>
                  </ul>
                </div>
      
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h4>聯繫我們</h4>
                  <p>
                    <strong>Email:</strong> <a href="mailto:xbridge.edu@gmail.com">xbridge.edu@gmail.com<br/></a>
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-info">
                  <h3>關於橫渠</h3>
                  <p>橫渠產學聯盟致力於導引學生探索建立個人品牌，強化產業思維，讓所學能有所用。</p>
                  <div className="social-as mt-3">
                    {/* <a href="/services" className="twitter"><i className="bx bxl-twitter"></i></a>
                    <a href="/services" className="facebook"><i className="bx bxl-facebook"></i></a>
                    <a href="/services" className="instagram"><i className="bx bxl-instagram"></i></a>
                    <a href="/services" className="aedin"><i className="bx bxl-aedin"></i></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
    
          <div className="container">
            <div className="copyright">
              &copy; Copyright <strong><span>XBRIDGE 橫渠產學聯盟</span></strong>. 版權所有
            </div>
          </div>
        </footer>
    )
}