import {serviceOutline} from '../context/serviceOutline';


export default function Service() {
    console.log(serviceOutline)
    return(
        <div>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                    <h2>服務</h2>
                    <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>服務</li>
                    </ol>
                    </div>
                </div>
                </section>
                <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                    <h2>服務</h2>
                    <ol>
                        <li><a href="/">橫渠產學聯盟</a></li>
                        <li>服務</li>
                    </ol>
                    </div>

                </div>
                </section>

                <section className="service-details">
                
                <div className="container">
                    <div className="section-title">
                        <h2>六大核心課程板塊，培育新一代創新人才</h2>
                        <p>橫渠的服務</p>
                    </div>
                    <div className="row">
                        {serviceOutline.map((item,index) => (
                            <div className="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                                <div className="card">
                                    <div className="card-img">
                                        <img 
                                            src={item.image}
                                            alt={item.title}
                                        
                                        />
                                    </div>
                                    
                                    <div className="card-body">
                                        <h5 className="card-title"><a href="/service">{item.title}</a></h5>
                                        <p className="card-text" style={{'whiteSpace': 'pre-line'}} >{item.description}</p>
                                        <div className="read-more"><a href="/service"><i className="icofont-arrow-right"></i> Read More</a></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
                </section>
                <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
                <div className="container">

                    <div className="row">
                    <div className="col-lg-6 video-box">
                        <img 
                            src="/assets/img/why-us.jpg" 
                            className="img-fluid" 
                            alt=""
                            
                        />
                        <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
                    </div>

                    <div className="col-lg-6 d-flex flex-column justify-content-center p-5">

                        <div className="icon-box">
                        <div className="icon"><i className="bx bx-fingerprint"></i></div>
                        <h4 className="title"><a href="">當學生加入橫渠，我們將輔導學生打造個人品牌，成為有創造力的人才。</a></h4>
                        <p className="description"><br/>國高中唸書的目標是進入名牌大學。反而進了大學之後，目標變成模糊空洞了。加入橫渠，我們將輔導學生打造個人品牌，成為有創造力的人才。</p>
                        </div>

                        <div className="icon-box">
                        <div className="icon"><i className="bx bx-gift"></i></div>
                        <h4 className="title"><a href="">當企業加入橫渠，能獲得具有格局卓見且富影響力，或有創見並能堅定實現的人才</a></h4>
                        <p className="description">大學並非高中的延伸。大學是為進入產業作準備。但是學校並沒有針對這方面的培訓。不僅產學之間脫節，還無法誘發學習動機。</p>
                        </div>

                    </div>
                    </div>

                </div>
                </section>
                
                <section className="pricing section-bg" data-aos="fade-up">
                    <div className="container">

                        <div className="section-title">
                            <h2>會費</h2>
                            <p>歡迎認同橫渠教育理念的有志之士一同加入，一起共建橫跨產學與教育之橋！</p>
                        </div>

                        <div className="row no-gutters">

                            <div className="col-lg box">
                                <h3>個人會員</h3>
                                <div style={{
                                    'display': 'flex',
                                    'justifyContent': 'center',
                                    'alignItems': 'center',
                                    'gap': '3rem',
                                    'marginTop': '3rem',
                                    'marginBottom': '2rem',
                                }}>
                                    <h4><span>入會費</span>NT 3000</h4>
                                    <h4><span>常年會費</span>NT 1500</h4>
                                </div>
                                <ul>
                                    <li><i className="bx bx-check"></i> 參與推動橫渠產學教育。</li>
                                    <li><i className="bx bx-check"></i> 具有參加橫渠舉辦的所有活動的機會。</li>
                                </ul>
                                <a href="#" className="get-started-btn">成為個人會員</a>
                            </div>

                            {/* <div className="col-lg-3 box featured">
                                <h3>學生會員</h3>
                                <h4>免費<span>per year</span></h4>
                                <ul>
                                <li><i className="bx bx-check"></i> 參與會務表現良好的學生，有被推薦實習的機會。</li>
                                <li><i className="bx bx-check"></i> 義務任職爲幹部，承擔工作組的規劃執行推動工作。</li>
                                <li><i className="bx bx-check"></i> 學生會員免入會費及年費。</li>
                                </ul>
                                <a href="#" className="get-started-btn">成為學生會員</a>
                            </div> */}

                            <div className="col-lg box">
                                <h3>團體會員</h3>
                                <div style={{
                                    'display': 'flex',
                                    'justifyContent': 'center',
                                    'alignItems': 'center',
                                    'gap': '3rem',
                                    'marginTop': '3rem',
                                    'marginBottom': '2rem',
                                }}>
                                <h4><span>入會費</span>NT 6000</h4>
                                <h4><span>常年會費</span>NT 3000</h4>
                                </div>
                                <ul>
                                <li><i className="bx bx-check"></i> 團體會員配合橫渠推薦之實習生作審查後，進行教育輔導，對實習生均給予合理待遇。</li>
                                <li><i className="bx bx-check"></i> 大學及民間人才專業培訓機構或人才介紹機構。</li>
                                </ul>
                                <a href="#" className="get-started-btn">成為團體會員</a>
                            </div>
                            {/* <div className="col-lg-3 box">
                                <h3>贊助會員</h3>
                                <h4>不限<span>per year</span></h4>
                                <ul>
                                <li><i className="bx bx-check"></i> 認同橫渠，願意給予資金支持，補助資金將進入橫渠教育專戶，專用於補貼大學產學教育發展工作。</li>
                                </ul>
                                <a href="#" className="get-started-btn">成為贊助會員</a>
                            </div> */}
                        </div>

                    </div>
                </section>
        </div>
    )
}