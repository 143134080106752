import Nav from './Nav'
import Footer from './Footer'
import { Outlet } from "react-router-dom";
import { useEffect } from 'react';
import SuspendButton from "./suspend-button";
import "./style/suspend-button.css";

function backToTop(){

    window.scrollTo(0, 0);
}

export default function Main() {

    const contactStyle = {
        marginBottom: "160px",
    }
    return(
        <div>
        <header id="header" className="fixed-top d-flex align-items-center ">
          <div className="container d-flex justify-content-between align-items-center">
            <div className="logo">
              <div className="row">
                <div className="col-2 pt-1">
                  <img
                    src="/assets/img/logo.png" 
                    alt="" 
                    className="img-fluid"

                  />
                </div>
                <div className="col-10">
                  <h1 className="text-light">
                    <a href="/">
                      <span>橫渠產學聯盟</span>
                    </a>
                  </h1>
                </div>
              </div>
            </div>
            <Nav/>
          </div>
        </header>
        <main style={contactStyle}>
          <Outlet />
        </main>
        <Footer />
        {/* onclick redirect to /contact  */}
        {/* <SuspendButton
          className="btn btn-primary"
          onClick={backToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 9999,
          }}
        >
         
        </SuspendButton> */}
        
        <SuspendButton style={{top:"80vh",right:"3%",borderRadius:"1rem",boxShadow: "0px 0px 5px rgb(0 0 0 / 40%)",backgroundColor:"white"}} img="/suspend.png" onClick={console.log("fjfjfjfj")} >
        </SuspendButton>
      <div className="back-to-top d-flex align-items-center justify-content-center" onClick={backToTop}><i className="bi bi-arrow-up-short"></i></div>
      </div>
    )
}