export const events = [
    {
      // title: "2022 4月",
      cardTitle: "12.28 講座 未來智慧工廠 X 橫渠產學聯盟",
      url: "https://www.facebook.com/atelierfutureNCKU",
      cardSubtitle:
        "",
      cardDetailedText: ``,
      media: {
        name: "dunkirk beach",
        source: {
          url:
            "/assets/img/Event/1.png"
        },
        type: "IMAGE"
      },
    },
    {
      // title: "2022 7月",
      cardTitle: "成大 CCP X 橫渠產學聯盟",
      url: "https://www.facebook.com/profile.php?id=100064425172196",
      cardSubtitle:
        "",
      cardDetailedText: ``,
      media: {
        name: "dunkirk beach",
        source: {
          url:
            "/assets/img/Event/2.png"
        },
        type: "IMAGE"
      },
    },
    {
      // title: "2022 8月",
      cardTitle: "橫渠理監事會",
      url: "https://www.facebook.com/profile.php?id=100084517613253",
      cardSubtitle:
        "",
      cardDetailedText: ``,
      media: {
        name: "dunkirk beach",
        source: {
          url:
            "/assets/img/Event/3.png"
        },
        type: "IMAGE"
      },
    },
    {
      // title: "2023 1月",
      cardTitle: "教育部資科司iLink計畫",
      url: "https://www.ilink-hss.org/",
      cardSubtitle:
        "",
      cardDetailedText: ``,
      media: {
        name: "dunkirk beach",
        source: {
          url:
            "/assets/img/Event/4.png"
        },
        type: "IMAGE"
      },
    }
    ];
    
    