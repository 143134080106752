import logo from './logo.svg';
import './App.css';
import Home from './pages/index';
import Team from './pages/Team';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Article from './pages/Article';
import Gallery from './pages/Gallery';
import Photo from './pages/Photo';
import Roadmap from './pages/Roadmap';
import Contact from './pages/Contact';
import Main from './components/Main';
import NoPage from './pages/NoPage';
import ComingSoon from './pages/ComingSoon';
import Member from './pages/Member';
import Activity from './pages/Activity';
import { BrowserRouter , Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>           
        <Routes>
            <Route path="/" element={<Main />}>
                <Route index element={<Home />} />
                
                <Route path="about" element={<About />} />
                <Route path="team" element={<Team />} />
                <Route path="member" element={<Member />} />
                <Route path="services" element={<Services />} />
                <Route path="partner" element={<ComingSoon />} />
                <Route path="blog" element={<Blog />} />
                <Route path="blog/:id" element={<Article />} />
                <Route path="activity" element={<Activity />} />
                <Route path="photo" element={<Gallery />} />
                <Route path="photo/:id" element={<Photo />} />
                <Route path="roadmap" element={<Roadmap />} />
                <Route path="contact" element={<Contact />} />
                <Route path="*" element={<NoPage />} />
            </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
