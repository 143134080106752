import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function Calender() {
    const [value, onChange] = useState(new Date());

  return (
    <div>
        <h2
            style={{
                // textAlign: 'center',
                fontSize: '30px',
                fontWeight: 'bold',
                color: '#1e4356',
                marginBottom: '20px',

            }}
        >活動日曆</h2>
        <Calendar onChange={onChange} value={value} />
    </div>
  );
}
