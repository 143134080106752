
import React from "react"
import { useEffect,useState } from "react";
import { Chrono } from "react-chrono";
import {milestone} from '../context/milestone';
import Uvp from '../components/Uvp'
export default function Home() {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
const IW = width <= 768 ? 200 : 300;
const SACH = width <= 768 ? false : true;

  return (
    <div >

      <section id="hero" className="d-flex justify-cntent-center align-items-center">
        <div id="heroCarousel" className="container carousel carousel-fade" data-bs-ride="carousel" data-bs-interval="5000">


          <div className="carousel-item active">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">歡迎來到 <span>橫渠產學聯盟</span></h2>
              <p className="animate__animated animate__fadeInUp">橫渠致力於為台灣打造未來以能力為核心的大學產業創新人才培育生態圈</p>
              <a href="/About" className="btn-get-started animate__animated animate__fadeInUp">了解更多理念</a>
            </div>
          </div>


          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">架橋、育苗、連結</h2>
              <p className="animate__animated animate__fadeInUp">橫渠專注產業思維教育，提升學生的軟實力，並連結其他專業技能夥伴，使所學能有所用。</p>
              <a href="/About" className="btn-get-started animate__animated animate__fadeInUp">橫渠的價值主張</a>
            </div>
          </div>


          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">過河要搭橋，造林要育苗。</h2>
              <p className="animate__animated animate__fadeInUp">加入橫渠，一起共創價值！</p>
              <a href="/Services" className="btn-get-started animate__animated animate__fadeInUp">了解入會資訊</a>
            </div>
          </div>

          <a className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
            <span className="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
          </a>

          <a className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
            <span className="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
          </a>

        </div>
      </section>

      <main id="main">
        <Uvp/>
        <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 video-box">
                <div className="col-lg-12 p-3">
                  <iframe width="100%" height="400" src="https://www.youtube.com/embed/6HYIuOpYPWc" title="台灣科技人才未來發展 楊光磊精闢解析【人物專訪 楊光磊】1111" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
              </div>
              

              <div className="col-lg-6 d-flex flex-column justify-content-center p-5">

                <div className="icon-box">
                  <div className="icon"><i className="bx bx-fingerprint"></i></div>
                  <h4 className="title"><a href="">專注創新人才和創新產業</a></h4>
                  <p className="description">平台發展，海納百川，永續運營，供需媒合</p>
                </div>

                <div className="icon-box">
                  <div className="icon"><i className="bx bx-gift"></i></div>
                  <h4 className="title"><a href="">人盡其才，發掘天賦，及早定位</a></h4>
                  <p className="description">解決台灣人才延遲發展與產業缺創新人才的困境</p>
                </div>

              </div>
            </div>

          </div>
        </section>

        <section className="features" style={{paddingBottom:"0"}}>
          <div className="container">

            <div className="section-title">
              <h2>建造偉大的橋樑，並非一蹴可幾！</h2>
              <p>橫渠里程碑</p>
            </div>

            <div className="row" data-aos="fade-up">
            <div style={{ width: "100%", height: "700px" }}>
                <Chrono 
                    items={milestone} 
                    mode="HORIZONTAL" 
                    slideShow  
                    cardWidth="300"
                    itemWidth={IW}
                    showAllCardsHorizontal={SACH}
                    theme = { { 
                      primary : '#1e4356' , 
                      secondary : '#68A4C4' , 
                      titleColor : 'black' , 
                      titleColorActive : 'white' , 
                    } } 
                    
                  />
                </div>
            </div>
          </div>
        </section>
        <section>
          <section className="why-us section-bg" data-aos="fade-up" date-aos-delay="200">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 d-flex flex-column justify-content-center p-5">
      
                  <div className="col-md-7 pt-5 order-2 order-md-1">
                    <h3>過河要搭橋，造林要育苗。</h3>
                    <p className="fst-italic">
                      加入橫渠，一起共創價值！
                    </p>
                    <a href="/Contact" className="btn-get-started animate__animated animate__fadeInUp">填寫聯絡資訊</a>
                  </div>
                </div>
                <div className="col-md-5 order-1 order-md-2">
                  <img 
                    src="/assets/img/features-4.svg" 
                    className="img-fluid" 
                    alt=""
                    width={300}
                    height={300}
                  />
                </div>
              </div>
      
            </div>
          </section>
        </section>
      </main>
    </div>
  )
}
