export const UVPContext = [
    {
      "id": "1",
      "title": "引導學生建立為產而學的動機",
      "image": "/assets/img/service/1.png",
      "description": "某年輕人，矢志成為一代鑄劍師，因此窮其心身，挑燈苦讀，鍛鍊身體，遍訪名師, 勤於鍛造練習，終成一代宗師。正因他心有志向，非但沒有躺平，還能高強度的要求自己。而這恰好是我們大學的反面寫照。學生沒有學習動機，不知為何而學。",
      "link": "",
      "display": "true",
    },
    {
        "id": "2",
        "title": "產業實務課程",
        "image": "/assets/img/service/2.png",
        "description": "為求「為產而學」，橫渠引導產業人士參與產業教育活動。透過安排課程及實習方式，觸發學生早期認知產業，引領其找定適合自己的職涯目標。激發學習動機，育成企業良材。\n以業師方式，配合大學開設產業實務課程。引導學生認識產業及職務，即指導學生自行在網上學習專業。這是最務實的產業教育。早期技職體系的老師多為產業有經驗的專家。現在的大學，無論是否為技職，都沒有這類老師, 致使畢業生能力不符產業所需、所用, 因此引進業師是最可行的解決方式。",
        "link": "",
        "display": "true",
    },
    {
      "id": "3",
      "title": "建立新實習模式",
      "image": "/assets/img/service/3.png",
      "description": "實習素為學生最接近產業實景的窗口，也幾乎是學涯中接觸產業之唯一機會。惜哉台灣的大學及產業，在實習這項關鍵活動，並未能建立實質的實習，絕大部份更流於工讀性質。之所以如此，原因有三：\n學生對於產業過於生疏，學校沒有預先給予適當的教育。學生要去實習，並沒有預先作好準備。學生選擇實習，抱持嚐鮮心態。企業以利益為先，不可能為沒準備的學生，提供無償的教育服務。\n企業之所以歡迎實習生，很大的程度是為達徵才之目的。然而，如今大學生多計畫繼續唸研究所。因此並不為求職而來。以企業而言，失去合理接納實習生的動機。而至於唸研究所的學生，又因為要專注於論文報告，也不太可能分心於實習工作, 致使兩頭落空。\n3）學生實習要有若干戰力，能幫助企業解決一些問題，並且有一段足夠長的工作時間。這種以帶有任務的實習模式，有相當吸引力。前提是學生要有些許戰力，而非純粹白紙一張。亦即學生去實習，應該提前建立某種專業能力，應徵這類任務實習。",
      "link": "",
      "display": "true",
    },
    {
      "id": "4",
      "title": "學生需有產業輔導教練",
      "image": "/assets/img/service/4.png",
      "description": "學生越早確立志向，強化能力的準備期也越長。困惑之處在於不清楚自己將來適合作什麼？愛做什麼？機會、前途在哪？這需有更充分的時間探索，亦需有產界資深人士來引導。教練模式不同於受教學習，學生此刻是準備上線的選手，教練僅於一旁協助、給予意見及引導。",
      "link": "",
      "display": "true",
    },
    {
      "id": "5",
      "title": "橫渠要作穿針引線人",
      "image": "/assets/img/service/5.png",
      "description": "台灣社會有許多公益團體或零散的個人, 有熱誠提供各類教育及服務。但彼此之間卻欠缺整合。站在最前沿的橫渠，應扮演好這些教育組織的引導者，與學校合作，有效串連後面一系列的教育服務。",
      "link": "",
      "display": "true",
    },
        
  ];
  
  