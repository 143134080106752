import React from "react";
import "./style/spinner.css";

export default function LoadingSpinner() {
  return (
    // <div className="spinner-container">
    //   <div className="loading-spinner">
    //   </div>
    // </div>
    <div className="loadingbox">
        <div className="spinner-box">
            {/* <div id="spinner-container"> */}
                <img src="/loading.gif" alt="loading" width={200} data-aos="fade-up" data-aos-easing="ease-in-out" />
                {/* <svg viewBox="0 0 100 100">
                    <defs>
                        <filter id="shadow">
                        <feDropShadow dx="0" dy="0" stdDeviation="0.5" 
                            floodColor="#1e4356"/>
                        </filter>
                    </defs>
                    <circle id="spinner" style={{fill:"transparent",stroke:"#1e4356",strokeWidth: "7px",strokeLinecap: "round",filter:"url(#shadow)"}} cx="50" cy="50" r="45"/>
                </svg> */}
            {/* </div> */}
        </div>
    </div>
  );
}
